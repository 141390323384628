/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC, useCallback, useEffect } from 'react'
import useCurrentUser from '../hooks/useCurrentUser'
import { X_AUTH_TOKEN } from './CONSTANT_HEADERS'
import { readToken } from './JWTToken'
import { getCookie } from '../utillities/Cookies'
import Gleap from 'gleap'
import useAppRouter from '../hooks/useAppRouter'

export const createSingleTimeInvoke = <F extends Function>(func: F) => {
  let invoked = false
  return function (...args) {
    if (invoked === false) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      func(...args)
      invoked = true
    }
  }
}

const innitalize = createSingleTimeInvoke((apiKey) => {
  Gleap.initialize(apiKey)
  window.Gleap = Gleap
})
const GleapWidget: FC<{ apiKey: string }> = ({ apiKey }) => {
  const token = getCookie(X_AUTH_TOKEN)
  const router = useAppRouter()
  const jwt = readToken(token)
  const [currentUser] = useCurrentUser()
  const checkAndOpenChat = useCallback(() => {
    if (router.query.openChat) {
      Gleap.open()
    }
  }, [router])
  useEffect(() => {
    if (window.Gleap) {
      checkAndOpenChat()
    }
  }, [checkAndOpenChat, router])
  useEffect(() => {
    innitalize(apiKey)
    if (jwt && jwt.user_type !== undefined) {
      if (currentUser) {
        //@ts-ignore
        if (typeof window?.Gleap === 'function') {
          //@ts-ignore
          window?.Gleap.identify(
            currentUser?.id,
            {
              name: currentUser?.full_name,
              email: currentUser?.email
            },
            'GENERATED_USER_HASH'
          )
          //@ts-ignore
          // window?.Gleap.registerCustomAction((customAction) => {
          //   if (customAction.name === 'openCrispChat') {
          //     if (window.$crisp) {
          //       window.$crisp.push(['do', 'chat:open'])
          //     }
          //   }
          // })
        }
      }
    }

    return () => {
      // consolog aim to destroy
    }
  }, [jwt?.user_type, currentUser])
  return <></>
}
export default GleapWidget
