import { useAmp } from 'next/amp'
import Image from 'next/image'
import {
  LINK_FOOTER_JOBS,
  LINK_URL_BLOG_CATEGORY,
  LINK_URL_DOWNLOAD_ANDROID,
  LINK_URL_DOWNLOAD_APPLE,
  LINK_URL_EMPLOYER,
  LINK_URL_FACEBOOK,
  LINK_URL_FOOTER_COMPANIES,
  LINK_URL_FOOTER_LP_CV_ONLINE,
  LINK_URL_FOOTER_LP_HEADHUNT_SERVICE,
  LINK_URL_GROSS_NET,
  LINK_URL_INSTAGRAM,
  LINK_URL_LINKIN,
  LINK_URL_YOUTUBE
} from '../../utillities/LinkURL'
import { SEO_CONS_TITLE_DEFAULT } from '../../utillities/SEOConstraint'
import LinkWrapper from '../Link'
import APPLE_STORE from './assets/apple_store.svg'
import GOOGLE_PLAY from './assets/google_play.svg'
import GOV from './assets/gov.png'
import LOGO from './assets/logo.svg'
import FACEBOOK from './assets/facebook.svg'
import LINKEDIN from './assets/linkedin.svg'
import INSTAGRAM from './assets/instagram.svg'
import YOUTUBE from './assets/youtube.svg'
import { useTranslateLink } from '../../hooks/useTranslateLink'
import { toCategorySEOPath, toSEOPathNameV2 } from '../../utillities/SEOUtilities'
import { useTranslation } from '../../hooks/useTranslation'
import env from '../../env'
import DIRECTIONAL_ICON from './assets/icon_outlined_directional_double-right'
import { LINK_URL_CV_EXAMPLES } from '../../utillities/URL'
const listLocations = [
  {
    id: 27,
    name: 'Ho Chi Minh',
    name_vn: 'Hồ Chí Minh'
  },
  {
    id: 22,
    name: 'Ha Noi',
    name_vn: 'Hà Nội'
  },
  {
    id: 14,
    name: 'Da Nang',
    name_vn: 'Đà Nẵng'
  },
  {
    id: 8,
    name: 'Binh Duong',
    name_vn: 'Bình Dương'
  },
  {
    id: 39,
    name: 'Others',
    name_vn: 'Khác'
  }
]

const listJobCategories = [
  {
    id: 1,
    name: 'HR & Recruitment',
    name_vn: 'Nhân sự & Tuyển dụng',
    slug_en: 'hr-recruitment',
    slug_vi: 'nhan-su-tuyen-dung'
  },
  {
    id: 28,
    name: 'Operations/CS/Adminstrative',
    name_vn: 'Hành chính/Chăm sóc khách hàng',
    slug_en: 'operations-cs-administrative',
    slug_vi: 'hanh-chinh-cham-soc-khach-hang'
  },
  {
    id: 36,
    name: 'Accounting & Finance',
    name_vn: 'Kế Toán & Tài chính',
    slug_en: 'accounting-finance',
    slug_vi: 'ke-toan-tai-chinh'
  },
  {
    id: 46,
    name: 'Marketing & PR',
    name_vn: 'Marketing & PR',
    slug_en: 'marketing-pr',
    slug_vi: 'marketing-pr'
  },
  {
    id: 61,
    name: 'Sales & Business Development',
    name_vn: 'Kinh doanh & Bán hàng',
    slug_en: 'sales-business-development',
    slug_vi: 'kinh-doanh-ban-hang'
  }
]

const Footer = () => {
  const isAmp = useAmp()
  const { currentLanguage, translate, switchLanguage } = useTranslation()
  const translateLink = useTranslateLink()
  // const Mixpanel = useMixpanel() // Who need ?

  return (
    <>
      <div className="footer-view">
        <div className="wrapper-container">
          <div className="footer-grid">
            <div className="grid-1">
              <div className="d-flex flex-column">
                <div className="footer-title">{translate('footer:about_freecracy')}</div>
                <div className="footer-mobile">
                  <a
                    className="footer-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${translateLink(`${env.PUBLIC_APP_DOMAIN}/company/${env.PUBLIC_FOOTER_ABOUT_COMPANY_SLUG}`)}`}>
                    <div className="footer-sub-title">{translate('footer:about_us')}</div>
                  </a>
                  <a
                    className="footer-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${switchLanguage({
                      vi: `https://wlqperwzc.gleap.help/vi/articles/6-Thoa-Thuan-Su-Dung`,
                      en: `https://wlqperwzc.gleap.help/en/articles/6-TERMS-OF-USE`
                    })}`}>
                    <div className="footer-sub-title">{translate('footer:terms')}</div>
                  </a>
                  <a
                    className="footer-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${switchLanguage({
                      vi: `https://wlqperwzc.gleap.help/vi/articles/1-QUY-DJINH-BAO-MAT`,
                      en: `https://wlqperwzc.gleap.help/en/articles/1-PRIVACY-POLICY`
                    })}`}>
                    <div className="footer-sub-title">{translate('footer:policy')}</div>
                  </a>
                  <a className="footer-link" target="_blank" rel="noopener noreferrer" href={`${env.PUBLIC_FOOTER_JOB_OPPORTUNITY}`}>
                    <div className="footer-sub-title">{translate('footer:careers')}</div>
                  </a>
                  <a
                    className="footer-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${env.PUBLIC_APP_DOMAIN}${env.PUBLIC_FOOTER_CONTACT}`}>
                    <div className="footer-sub-title">{translate('footer:contact_us')}</div>
                  </a>
                  <a
                    className="footer-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${switchLanguage({ vi: `https://wlqperwzc.gleap.help/vi`, en: `https://wlqperwzc.gleap.help/en` })}`}>
                    <div className="footer-sub-title">{translate('header:help_center')}</div>
                  </a>
                </div>
              </div>
            </div>
            <div className="grid-2">
              <div className="d-flex flex-column">
                <div className="footer-title">{translate('footer:for_employers')}</div>
                <div className="footer-mobile">
                  <a className="footer-link" target="_blank" rel="noopener noreferrer" href={LINK_URL_EMPLOYER}>
                    <div className="footer-sub-title">{translate('footer:post_job_for_free')}</div>
                  </a>
                  <a
                    className="footer-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${LINK_URL_FOOTER_LP_HEADHUNT_SERVICE}?utm_campaign=HEADHUNTER_LOCAL&utm_source=Employer-LP&utm_medium=footage-employee`}>
                    <div className="footer-sub-title">{translate('footer:headhunting_services')}</div>
                  </a>
                  <a className="footer-link" target="_blank" rel="noopener noreferrer" href={LINK_URL_BLOG_CATEGORY}>
                    <div className="footer-sub-title">{translate('footer:guideline_for_recruiters')}</div>
                  </a>
                  <a
                    className="footer-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://blog.freec.asia/60-mau-tin-dang-tuyen-dung-cac-nganh-nghe/`}>
                    <div className="footer-sub-title">{translate('footer:job_description_template')}</div>
                  </a>
                </div>
              </div>
            </div>
            <div className="grid-3">
              <div className="d-flex flex-column">
                <div className="footer-title">{translate('footer:category_search')}</div>
                <div className="footer-mobile">
                  {listJobCategories.map((i) => (
                    <LinkWrapper
                      prefetch={false}
                      key={i.id}
                      href={toCategorySEOPath({
                        lang: currentLanguage,
                        slug_vi: i.slug_vi,
                        slug_en: i.slug_en,
                        categoryId: i.id
                      })}>
                      <a target="_blank" rel="noopener noreferrer" className="footer-sub-title">
                        {currentLanguage === 'en' ? i.name : i.name_vn}
                      </a>
                    </LinkWrapper>
                  ))}
                  <LinkWrapper prefetch={false} href={'/work-from-home'}>
                    <a rel="noopener noreferrer" target="_blank" className="footer-sub-title">
                      {currentLanguage === 'en' ? 'Remote jobs' : 'Việc làm từ xa'}
                    </a>
                  </LinkWrapper>
                </div>
              </div>
            </div>
            <div className="grid-4">
              <div className="d-flex flex-column">
                <div className="footer-title">{translate('footer:download_our_app')}</div>
                <div className="footer-mobile footer-mobile-download">
                  <a target="_blank" rel="noopener noreferrer" href={LINK_URL_DOWNLOAD_APPLE}>
                    <div className="footer-app-icon">
                      {isAmp === true ? (
                        <amp-img
                          title={SEO_CONS_TITLE_DEFAULT}
                          width={135}
                          height={48}
                          src={APPLE_STORE?.src}
                          alt="apple store"
                          layout="responsive"
                        />
                      ) : (
                        <Image title={SEO_CONS_TITLE_DEFAULT} src={APPLE_STORE} alt="apple store" width={135} height={48} />
                      )}
                    </div>
                  </a>
                  <a target="_blank" rel="noopener noreferrer" href={LINK_URL_DOWNLOAD_ANDROID}>
                    <div className="footer-app-icon">
                      {isAmp === true ? (
                        <amp-img width={135} height={48} src={GOOGLE_PLAY?.src} alt="android store" layout="responsive" />
                      ) : (
                        <Image title={SEO_CONS_TITLE_DEFAULT} src={GOOGLE_PLAY} alt="android store" width={135} height={48} />
                      )}
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className="grid-5">
              <div className="d-flex flex-column">
                <div className="footer-title">{translate('footer:for_candidates')}</div>
                <div className="footer-mobile">
                  <a className="footer-link" target="_blank" rel="noopener noreferrer" href={translateLink(LINK_FOOTER_JOBS)}>
                    <div className="footer-sub-title">{translate('footer:find_jobs')}</div>
                  </a>
                  <a className="footer-link" target="_blank" rel="noopener noreferrer" href={translateLink(LINK_URL_FOOTER_COMPANIES)}>
                    <div className="footer-sub-title">{translate('footer:list_companies')}</div>
                  </a>
                  <a className="footer-link" target="_blank" rel="noopener noreferrer" href={'https://blog.freec.asia/ung-vien/'}>
                    <div className="footer-sub-title">{translate('footer:guideline_for_candidates')}</div>
                  </a>
                  <a
                    className="footer-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    // data-mixpanel-event={Mixpanel.EVENTS.CLICK_CREATE_CV_FOOTER_MENU}
                    href={translateLink(LINK_URL_FOOTER_LP_CV_ONLINE)}>
                    <div className="footer-sub-title">{translate('footer:create_cv_coming')}</div>
                  </a>
                  {/* Please add data-mixpanel-event={Mixpanel.EVENTS.CLICK_SALARY_TOOL_FOOTER_MENU} for tag `a` when this feature go live */}
                  <a className="footer-link" target="_blank" rel="noopener noreferrer" href={LINK_URL_GROSS_NET}>
                    <div className="footer-sub-title">{translate('footer:calculate_gross_net')}</div>
                  </a>
                  <a className="footer-link" target="_blank" rel="noopener noreferrer" href={LINK_URL_CV_EXAMPLES}>
                    <div className="footer-sub-title">{translate('header:cv_examples')}</div>
                  </a>
                </div>
              </div>
            </div>
            <div className="grid-6">
              <div className="d-flex flex-column">
                <div className="footer-title">{translate('footer:work_location')}</div>
                <div className="footer-mobile">
                  {listLocations.map((i) => (
                    <LinkWrapper prefetch={false} key={i.id} href={toSEOPathNameV2({ locationId: i.id }, currentLanguage)}>
                      <a rel="noopener noreferrer" target="_blank" className="footer-sub-title">
                        {currentLanguage === 'en' ? i.name : i.name_vn}
                      </a>
                    </LinkWrapper>
                  ))}
                  <LinkWrapper prefetch={false} href={'/job-by-location'}>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      className="footer-sub-title"
                      style={{ display: 'flex', alignItems: 'center' }}>
                      {currentLanguage === 'en' ? 'View all' : 'Xem tất cả'}
                      <div style={{ display: 'flex', alignItems: 'center', marginLeft: 8 }}>
                        <DIRECTIONAL_ICON />
                      </div>
                    </a>
                  </LinkWrapper>
                </div>
              </div>
            </div>
            <div className="grid-7">
              <div>
                <div className="footer-title">{translate('footer:connect_to_freec')}</div>
                <div className="d-flex group-social">
                  {isAmp === true ? (
                    <>
                      <a target="_blank" className="icon-social" rel="noopener noreferrer" href={LINK_URL_FACEBOOK}>
                        <amp-img title="facebook" src={FACEBOOK?.src} alt="facebook" width={8} height={14} />
                      </a>
                      <a target="_blank" className="icon-social" rel="noopener noreferrer" href={LINK_URL_LINKIN}>
                        <amp-img title="linkedin" src={LINKEDIN?.src} alt="linkedin" width={14} height={14} />
                      </a>
                      <a target="_blank" className="icon-social" rel="noopener noreferrer" href={LINK_URL_INSTAGRAM}>
                        <amp-img title="instagram" src={INSTAGRAM?.src} alt="instagram" width={14} height={14} />
                      </a>
                      <a target="_blank" className="icon-social" rel="noopener noreferrer" href={LINK_URL_YOUTUBE}>
                        <amp-img title="youtube" src={YOUTUBE?.src} alt="youtube" width={17} height={12} />
                      </a>
                    </>
                  ) : (
                    <>
                      <a target="_blank" className="icon-social" rel="noopener noreferrer" href={LINK_URL_FACEBOOK}>
                        <Image title="facebook" src={FACEBOOK} alt="facebook" width={8} height={14} />
                      </a>
                      <a target="_blank" className="icon-social" rel="noopener noreferrer" href={LINK_URL_LINKIN}>
                        <Image title="linkedin" src={LINKEDIN} alt="linkedin" width={14} height={14} />
                      </a>
                      <a target="_blank" className="icon-social" rel="noopener noreferrer" href={LINK_URL_INSTAGRAM}>
                        <Image title="instagram" src={INSTAGRAM} alt="instagram" width={14} height={14} />
                      </a>
                      <a target="_blank" className="icon-social" rel="noopener noreferrer" href={LINK_URL_YOUTUBE}>
                        <Image title="youtube" src={YOUTUBE} alt="youtube" width={17} height={12} />
                      </a>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-line" />
        <div className="footer-freec">
          <div className="wrapper-container">
            <div className="row align-items-center justify-content-space-between">
              <div className="row align-items-center">
                <div className="grid-1">
                  {isAmp === true ? (
                    <div style={{ width: 72, height: 48, margin: 'auto' }}>
                      <amp-img
                        width={72}
                        height={48}
                        src={LOGO?.src}
                        title={SEO_CONS_TITLE_DEFAULT}
                        alt="freec-platform"
                        layout="responsive"
                      />
                    </div>
                  ) : (
                    <Image title={SEO_CONS_TITLE_DEFAULT} src={LOGO} alt="freec-platform" width={72} height={48} />
                  )}
                </div>
                <div className="info-contact">
                  <strong>{translate('footer:new_copyright')}</strong>
                  <span>{translate('footer:info_phone')}</span>
                  <br />
                  <span>{translate('footer:info_address')}</span>
                  <br />
                  <span>
                    {translate('footer:info_certificate_name')}&nbsp; {translate('footer:info_certificate_number')}&nbsp;{' '}
                  </span>
                  <span>
                    {translate('footer:info_certificate_date_created')}&nbsp; {translate('footer:info_certificate_place')}
                  </span>
                </div>
              </div>
              <div className="right">
                <a target="_blank" rel="noopener noreferrer" href={'http://online.gov.vn/Home/WebDetails/65654'}>
                  <div className="footer-app-icon">
                    {isAmp === true ? (
                      <amp-img title={SEO_CONS_TITLE_DEFAULT} width={135} height={48} src={GOV?.src} alt="gov store" />
                    ) : (
                      <Image placeholder="blur" title={SEO_CONS_TITLE_DEFAULT} src={GOV} alt="gov store" width={135} height={48} />
                    )}
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer
